import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import storage from './utils/store'
import 'leaflet-draw'
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css'
import ElementUI from 'element-ui';
import request from "./utils/request";
import map from './utils/map'
import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.$request = request;
Vue.prototype.$map = map;
Vue.prototype.$storage = storage
Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
