class store {
    set userInfo(data) {
        localStorage.setItem('userInfo', JSON.stringify(data));
    }
    get userInfo() {
        return JSON.parse(localStorage.getItem('userInfo'));
    }
    set token(data) {
        localStorage.setItem('token', data);
    }
    get token() {
        return localStorage.getItem('token');
    }
    set permissions(data) {
        localStorage.setItem('permissions', data);
    }
    get permissions() {
        return localStorage.getItem('permissions')
    }
    clear() {
        localStorage.removeItem('userInfo')
        localStorage.removeItem('token')
        localStorage.removeItem('permissions')
    }
}

export default new store;