import L from 'leaflet'
class Map {
    //地图对象
    map = null
    layerGroup = []
    list = []//存放矩形点数据
    rectgroup = ''//矩形图层
    cirgroup = ''//点图层
    testgroup = ''//移动时绘制矩形图形的图层
    juxing = null


    //初始化地图
    initMap = function () {
        let tk = '2b5591d31e6c56fda5d3601a491f89cc'
        //添加zoom 到达12 显示的图层
        let url = "https://t{s}.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=" + tk;
        let baseMap = L.tileLayer(url,
            {
                zIndex: 18,
                minZoom: 3,
                maxZoom: 15,
                subdomains: ['0', '1', '2', '3', '4', '5', '6', '7']
            }
        )
        this.map = L.map("map", {
            layers: [baseMap],
            minZoom: 3,
            maxZoom: 15,
            attributionControl: false,
            crs: L.CRS.EPSG3857,
            // crs: L.CRS.EPSG3395,
            zoomControl: !1,
            keyboard: !1,
            worldCopyJump: !0,
            zoomAnimationThreshold: 3,
            fadeAnimation: !1,
            maxBounds: [[- 90, -180000000], [90, 180000000]]
        }).setView([40, 120], 5);

        let options2 = {
            zIndex: 20,
            minZoom: 3,
            maxZoom: 15,
            subdomains: ['0', '1', '2', '3', '4', '5', '6', '7']
        }
        let url2 = `https://t{s}.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=${tk}`
        this.taggingTileLayer = L.tileLayer(url2, options2).addTo(this.map)

        // 矩形相关图层
        this.rectgroup = L.featureGroup([]) //创建图层
        this.map.addLayer(this.rectgroup)
        this.cirgroup = L.featureGroup([]) //创建图层
        this.map.addLayer(this.cirgroup)
        this.testgroup = L.featureGroup([]) //创建图层
        this.map.addLayer(this.testgroup)
    }
    createRect(cb) {
        let list = []
        if (this.juxing) {
            this.removeRect(this.juxing)
            this.juxing = null
            this.list = []
        }
        this.map.on("click", (e) => {
            list.push([e.latlng.lat, e.latlng.lng])
            this.map.on('mousemove', (ev) => {
                if (list.length > 1) {
                    list.splice(1)
                }
                list.push([ev.latlng.lat, ev.latlng.lng])
                this.testgroup && this.testgroup.clearLayers()

                this.juxing = L.rectangle(list, {//绘制矩形
                    color: "#ff7800",
                    weight: 1
                }).addTo(this.testgroup);
            })
            if (list.length > 1) {
                list.pop()//第二次点击会触发第一次的push（）导致得到三个数据（后两个一样），所以删除最后一个
                // this.list.push([list])//保存矩形数据
                this.list.push({ first: list[0], sec: list[1], name: 'rect' + (this.list.length + 1) })//保存矩形数据
                this.map.off('mousemove')//两点确定，移除鼠标移动事件
                this.map.off('click')//两点确定，移除点击事件，
                this.testgroup && this.testgroup.clearLayers()
                this.juxing = L.rectangle(list, {//绘制矩形
                    color: "#ff7800",
                    weight: 1
                }).addTo(this.rectgroup);
                if (cb && this.list.length > 0) {
                    console.log(list);
                    console.log('this.list', this.list);
                    cb()
                }
            }
        })
    }
    // remove rect
    removeRect(layer) {
        layer && this.map.removeLayer(layer)
    }
    //  根据输入框数据变化重新画矩形
    setRect(list) {
        if (this.juxing) {
            this.removeRect(this.juxing)
            this.juxing = null
            this.list = []
        }
        this.juxing = L.rectangle(list, {//绘制矩形
            color: "#ff7800",
            weight: 1
        }).addTo(this.testgroup);
    }
}
export default new Map()