import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    redirect: '/main/drawings'
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('../layout/baseLayout.vue'),
    children: [
      { path: '/main/drawings', name: 'Drawings', meta: { title: '数据与图件定制' }, component: () => import('../pages/Drawings/index.vue') },
      { path: '/main/forecast', name: 'Forecast', meta: { title: '海洋预报' }, component: () => import('../pages/Forecast/index.vue') },
      { path: '/main/reanalysis', name: 'Reanalysis', meta: { title: '海洋再分析' }, component: () => import('../pages/Reanalysis/index.vue') },
      { path: '/main/baseData', name: 'BaseData', meta: { title: '基础数据' }, component: () => import('../pages/BaseData/index.vue') },
      { path: '/main/baseData/baseDataDetail', name: 'BaseDataDetail', meta: { title: '基础数据详情' }, component: () => import('../pages/BaseData/detail.vue') },
      { path: '/main/standard', name: 'Standard', meta: { title: '标准规范' }, component: () => import('../pages/Standard/index.vue') },
      { path: '/main/equipment', name: 'Equipment', meta: { title: '海洋仪器' }, component: () => import('../pages/Equipment/index.vue') },
      { path: '/main/equipment/equipmentDetail', name: 'EquipmentDetail', meta: { title: '海洋仪器详情' }, component: () => import('../pages/Equipment/detail.vue') },
      { path: '/main/software', name: 'Software', meta: { title: '软件程序' }, component: () => import('../pages/Software/index.vue') },
    ]
  },
  { path: "*", redirect: "/" }
]

const router = new VueRouter({
  routes
})
// 路由守卫
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();

});
export default router
