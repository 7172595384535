import axios from 'axios';
import store from './store'

const baseURL = window.myConfig.baseUrl
export default function (url = '', options = {}) {
    options.holeUrl ? '' : url = baseURL + url
    options.method = options.method ? options.method : 'post',
        options.headers = {
            'Content-Type': 'application/json; charset=UTF-8',
        }
    store.token && (options.headers.Authorization = store.token)
    //文件上传  接收常规数据 转 FormData
    if (options.method === 'fileUpload') {
        options.method = 'POST'
        delete (options.headers["Content-Type"])
        const dataParament = { ...options.data };
        let filedata = new FormData();
        for (let item in dataParament) {
            if (dataParament[item]) {
                if (dataParament[item].length && dataParament[item][0] instanceof File) {
                    for (let i = 0; i < dataParament[item].length; i++) {
                        filedata.append(item, dataParament[item][i])
                    }
                } else {
                    filedata.append(item, dataParament[item])
                }
            }
        }
        options.data = filedata
    }
    return new Promise((re, rc) => {
        axios({
            url,
            ...options,
        }).then(response => {
            if (response.status == 200) {
                let res = response.data
                if (options.responseType == "arraybuffer" || options.responseType == "blob") {
                    re(res)
                    return
                }
                if (res.errorCode == '00000') {
                    re(res)
                } else if (res.errorCode == 'A0200') {
                    //未登录或者登录异常
                    rc(res)
                } else {
                    rc(res)
                }
            } else {
                rc(response)
            }
        }).catch(error => {
            rc(error)
        })
    })
}
