<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
import L from 'leaflet'
export default {
    name: 'app',
    data() {
        return {

        }
    }
}
</script>
<style lang="less">
@import url(./assets/base.less);

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
// 滚动条整体样式
::-webkit-scrollbar {
    width: 12px;
    height: 1px;

    // 滚动条滑块
    &-thumb {
        background: #e6e9f7;
        border-radius: 5px;
    }

    // 滚动条轨道
    &-track {
        background: #f5f6fa;
        // background: transparent;
        border-radius: 5px;
    }
}

.el-table .el-table__header-wrapper {
    tr {
        th {
            background-color: #f7f7f7 !important;

            >.cell {
                color: #333;
                // font-size: 14px;
            }
        }
    }
}
</style>
